<template>
  <div class="home-platform">
    <p>自主创新数字化转型赋能云平台</p>
    <span>自主创新数字化转型搬运工</span>
    <div class="home-platform-content">
      <van-collapse v-model="activeNames" :border="false">
        <van-collapse-item title="解决方案" name="1">
          <div class="home-platform-category">
            <div v-for="el in solutionList" :key="el.id" @click="solutionBtn('solution', el.lastId, el.id)"><img :src="el.img" alt="jy" /><span>{{el.name}}</span></div>
          </div>
          <span class="home-platform-all" @click="productAll(1)">查看更多 ></span>
        </van-collapse-item>
        <van-collapse-item title="loT产品" name="2">
          <div class="home-platform-category">
            <div v-for="el in iotData" :key="el.id" @click="solutionBtn('iot', el.lastId, el.id)"><img :src="el.img" alt="jy" /><span>{{el.name}}</span></div>
          </div>
          <span class="home-platform-all" @click="productAll(2)">查看更多 ></span>
        </van-collapse-item>
        <van-collapse-item title="开发板" name="3">
          <div class="home-platform-product-box">
            <div class="home-platform-product">
                <div v-for="el in devData" :key="el.id" @click="productInfo(el.url)"><img :src="el.img" alt="jy" /><span>{{el.name}}</span></div>
            </div>
            <span class="home-platform-all" @click="productAll(3)">查看更多 ></span>
          </div>
        </van-collapse-item>
        <van-collapse-item title="SOC" name="4">
          <div class="home-platform-product-box">
            <div class="home-platform-product">
                <div v-for="el in socData" :key="el.id" @click="productInfo(el.url)"><img :src="el.img" alt="jy" /><span>{{el.name}}</span></div>
            </div>
            <span class="home-platform-all" @click="productAll(4)">查看更多 ></span>
          </div>
        </van-collapse-item>
        <van-collapse-item title="OS" name="5">
           <div class="home-platform-product-box product-os-bottom">
            <div class="home-platform-product">
                <div v-for="el in osData" :key="el.id" @click="productInfo(el.url)"><img :src="el.img" alt="jy" /><span>{{el.name}}</span></div>
            </div>
          </div>
        </van-collapse-item>
      </van-collapse>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeNames: ["1"],
      solutionList: [
        {
          lastId: "1500000000000000001",
          id: "1500000000000000015",
          name: "智能家居",
          img: require("@/assets/img/gyl123.png"),
        },
        {
          lastId: "1500000000000000002",
          id: "1500000000000000021",
          name: "金融",
          img: require("@/assets/img/jr1.png"),
        },{
          lastId: "1500000000000000002",
          id: "1500000000000000026",
          name: "交通",
          img:require('@/assets/img/jt123.png')
        },{
          lastId: "1500000000000000002",
          id: "1500000000000000023",
          name: "能源",
          img:require('@/assets/img/ny1.png')
        },{
          lastId: "1500000000000000002",
          id: "1500000000000000022",
          name: "党政",
          img:require('@/assets/img/dz.png')
        },{
          lastId: "1500000000000000002",
          id: "1500000000000000024",
          name: "教育",
          img:require('@/assets/img/jy12.png')
        },
        {
          lastId: "1500000000000000002",
          id: "1500000000000000025",
          name: "公检法",
          img:require('@/assets/img/gjf.png')
        },{
          lastId: "1500000000000000003",
          id: "1500000000000000032",
          name: "智能制造",
          img:require('@/assets/img/znzz123.png')
        },
      ],
      iotData: [
        {
          lastId: "1500000000000000001",
          id: "1500000000000000015",
          name: "智能家居",
          img: require("@/assets/img/iotjiaju.png"),
        },
        {
          lastId: "1500000000000000001",
          id: "1500000000000000011",
          name: "智慧办公",
          img: require("@/assets/img/iotzhbg.png"),
        },
        {
          lastId: "1500000000000000001",
          id: "1500000000000000012",
          name: "影音娱乐",
          img:require('@/assets/img/iotyyyl.png')
        },
        {
          lastId: "1500000000000000001",
          id: "1500000000000000013",
          name: "旅游出行",
          img:require('@/assets/img/iotcx.png')
        },
        {
          lastId: "1500000000000000001",
          id: "1500000000000000014",
          name: "运动健康",
          img:require('@/assets/img/iotydjk.png')
        },
        {
          lastId: "1500000000000000002",
          id: "1500000000000000021",
          name: "金融",
          img:require('@/assets/img/iotdzjiny.png')
        },
        {
          lastId: "1500000000000000002",
          id: "1500000000000000023",
          name: "能源",
          img:require('@/assets/img/iotny1.png')
        },
        {
          lastId: "1500000000000000002",
          id: "1500000000000000022",
          name: "党政",
          img:require('@/assets/img/iotdz.png')
        },
      ],
      devData: [
        {
          name: "Shenkaihong LYEVK-3861A",
          url: "/product/productInfo?id=1517038009399156738&type=dev",
          img:require('@/assets/img/ShenkaihongLYEVK-3861A@3x.png')
        },
        {
          name: "Shenkaihong LYEVK-3861B",
          url: "/product/productInfo?id=1517039175033987074&type=dev",
          img:require('@/assets/img/ShenkaihongLYEVK-3861A@3x.png')
        },
        {
          name: "KHDVK-3566B",
          url: "/product/productInfo?id=1518634156190404609&type=dev",
          img:require('@/assets/img/KHDVK-3566B@3x.png')
        },
        {
          name: "KHDVK-3588",
          url: "/product/productInfo?id=1518633081173512194&type=dev",
          img:require('@/assets/img/KHDVK-3588@3x.png')
        },
        {
          name: "HI3861 BearPi-HM Nano",
          url: "/product/productInfo?id=1517040093091635201&type=dev",
          img:require('@/assets/img/HI3861BearPi-HMNano@3x.png')
        },
        {
          name: "博流BL-HWC-G1",
          url: "/product/productInfo?id=1517071473536237569&type=dev",
          img:require('@/assets/img/BL-HWC-G1@3x.png')
        },
      ],
      socData: [
        {
          name: "LYB-S1B2 智能物联网模组",
          url: "/product/productInfo?id=1545309462745698306&type=soc",
          img:require('@/assets/img/LYB-S1B2@3x.png')
        },
        {
          name: "LYB-S3B2 智能物联网模组",
          url: "/product/productInfo?id=1545310793397030914&type=soc",
          img:require('@/assets/img/LYB-S3B2@3x.png')
        },
        {
          name: "智能电动车模块KHS-B1ET-A",
          url: "/product/productInfo?id=1545315562765275138&type=soc",
          img:require('@/assets/img/KHS-B1ET-A@3x.png')
        },
        {
          name: "LYB-P1B8 智能物联网模组",
          url: "/product/productInfo?id=1545316592148140034&type=soc",
          img:require('@/assets/img/LYB-P1B8@3x.png')
        },
        {
          name: "LYD-S1A1 智能物联网模组",
          url: "/product/productInfo?id=1545318816374968322&type=soc",
          img:require('@/assets/img/LYD-S1A1@3x.png')
        },
        {
          name: "C-8138 智能物联网模组",
          url: "/product/productInfo?id=1545317604921561089&type=soc",
          img:require('@/assets/img/C-8138@3x.png')
        },
      ],
      osData: [
        {
          name: "OpenHarmony V3.0 LTS",
          url: "/product/productInfo?id=1517029690550812674&type=os",
          img:require('@/assets/img/OpenHarmonyV3.0LTS@3x.png')
        },
        {
          name: "OpenHarmony V1.0 LTS",
          url: "/product/productInfo?id=1517056817530720258&type=os",
          img:require('@/assets/img/OpenHarmonyV1.0LTS@3x.png')
        },
        {
          name: "OpenHarmony V1.1.0-LTS",
          url: "/product/productInfo?id=1517030174397333505&type=os",
          img:require('@/assets/img/OpenHarmonyV1.1.0-LTS@3x.png')
        },
        {
          name: "OpenHarmony V1.1.1-LTS",
          url: "/product/productInfo?id=1517057323544137730&type=os",
          img:require('@/assets/img/OpenHarmonyV1.1.1-LTS@3x.png')
        },
        {
          name: "OpenHarmony V1.1.2-LTS",
          url: "/product/productInfo?id=1517058524755054594&type=os",
          img:require('@/assets/img/OpenHarmonyV1.1.2-LTS@3x.png')
        },
        {
          name: "OpenHarmony V1.1.3 LTS",
          url: "/product/productInfo?id=1517033587210223617&type=os",
          img:require('@/assets/img/OpenHarmonyV1.1.3 LTS@3x.png')
        },
      ],
    };
  },
  methods:{
    solutionBtn(type, lastId, id) {
      if (type == "solution") {
        this.$router.push(`/solution/searchList?id=${id}`)
      } else {
        this.$router.push(`/product?id=${id}`)
      }
    },
    productInfo(url) {
      this.$router.push(url)
    },
    productAll(index){
      if(index == 1){
        this.$router.push(`/solution/searchList`)
      }else if(index == 2){
        this.$router.push(`/product`)
      }else if(index == 3){
        this.$router.push(`/product/list?type=dev`)
      }else if(index == 4){
        this.$router.push(`/product/list?type=soc`)
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.home-platform {
  padding-top: 32px;
  > p {
    font-size: 20px;
    color: rgba(0, 0, 0, 0.85);
    text-align: center;
    line-height: 28px;
    margin-bottom: 8px;
    font-weight: 600;
  }
  > span {
    display: block;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.65);
    text-align: center;
    line-height: 20px;
    margin-bottom: 24px;
  }
  .home-platform-category{
    display: flex;
    flex-wrap: wrap;
    padding-top: 12px;
    >div{
        width: 25%;
        margin-bottom: 24px;
        >img{
            display: block;
            width: 44px;
            margin: 0 auto 8px;
        }
        >span{
            font-size: 12px;
            color: #333333;
            text-align: center;
            display: block;
            line-height: 16px;
        }
    }
  }
  .home-platform-product-box{
    padding: 8px 16px;
    >div{
        display: flex;
        flex-wrap: wrap;
        >div{
            width: 50%;
            padding: 12px 0;
            border-bottom: 1px solid rgba(0,0,0,0.09);
            display: flex;
            align-items: center;
            >img{
                width: 28px;
                margin-right: 8px;
            }
            >span{
                flex: 1;
                padding-right: 12px;
                font-size: 12px;
                color: rgba(0,0,0,0.85);
                line-height: 18px;
                max-height: 36px;
                overflow: hidden;
            }
        }
    }
    .home-platform-all{
        margin-top: 12px;
        padding-bottom: 14px;
    }
  }

  .product-os-bottom{
    >div{
      >:nth-last-of-type(-n+2){
        border-bottom: none;
      }
    }
  }


  .home-platform-all{
    font-size: 14px;
    color: rgba(0,0,0,0.45);
    display: block;
    text-align: center;
    line-height: 20px;
    padding-bottom: 24px;
  }
}

</style>
<style>
.home-platform-content .van-cell {
  padding: 15px 16px;
  background: #ebeef5;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.home-platform-content .van-cell::after {
  display: none;
}
.home-platform-content .van-collapse-item__content{
    padding: 0;
}
.home-platform-content .van-cell.van-collapse-item__title--expanded{
    border: 0;
}
</style>
