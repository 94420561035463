<template>
  <div class="home-serve">
    <p>自主创新数字化转型赋能全服务</p>
    <span>自主创新数字化转型全面服务能力</span>
    <strong>政府侧-对接园区</strong>
    <div class="home-consult">
      <div class="home-consult-swipe">
        <p class="home-consult-title">鸿联<span>资讯</span></p>
        <div>
          <van-swipe
            style="height: 44px"
            :autoplay="3000"
            vertical
            :show-indicators="false"
          >
            <van-swipe-item>
              <div class="home-consult-list">
                <p>
                  《中山市制造业数字化智能化转型银行贷款贴息与风险补偿实施细则》解读
                </p>
                <img src="@/assets/img/right@2x.png" alt="right" />
              </div>
            </van-swipe-item>
            <van-swipe-item>
              <div class="home-consult-list">
                <p>天津市促进首台（套）重大技术装备示范应用若干措施</p>
                <img src="@/assets/img/right@2x.png" alt="right" />
              </div>
            </van-swipe-item>
            <van-swipe-item>
              <div class="home-consult-list">
                <p>天津市促进内外贸一体化发展若干措施的通知</p>
                <img src="@/assets/img/right@2x.png" alt="right" />
              </div>
            </van-swipe-item>
          </van-swipe>
        </div>
      </div>
    </div>
    <div class="home-consult-map">
      <strong>重点园区介绍</strong>
      <div class="home-consult-map-total">
        <div>
          <p>
            <img
              src="@/assets/img/szicon.png"
              alt="数字化转型赋能园区"
            />数字化转型赋能园区 <span>19</span>
          </p>
          <p>
            <img
              src="@/assets/img/hmicon@2x.png"
              alt="鸿蒙化升级赋能园区"
            />鸿蒙化升级赋能园区 <span>4</span>
          </p>
        </div>
      </div>
      <div class="home-consult-map-info">
        <img class="info-img" src="../../assets/img/map.png" alt="">
        <div
          v-for="el in markList"
          :key="el.name"
          :style="{ left: el.left, top: el.top }"
        >
          <span class="map-icon1"><i></i></span>
          <p>{{ el.name }}</p>
        </div>
      </div>
      <div class="home-consult-map-park">
        <swiper
          ref="swiperThumbs"
          class="swiper subswiper-box"
          :options="swiperOptionThumbs"
        >
          <swiper-slide
            v-for="(el, index) in parkData"
            :key="index"
            class="home-consult-map-park-list"
          >
            <div>
              <img :src="el.img" :alt="el.title" />
              <p>{{ el.title }}</p>
            </div>
          </swiper-slide>
        </swiper>
        <!-- <div class="home-consult-map-park-list" v-for="el in parkData" :key="el.title">
          <img :src="el.img" :alt="el.title" />
          <p>{{el.title}}</p>
        </div> -->
      </div>
    </div>
    <div class="home-consult-enterprise">
      <strong>企业侧-赋能企业</strong>
      <span>金牌服务商</span>
      <div class="home-consult-enterprise-list">
        <swiper
          ref="swiperThumbs"
          class="swiper subswiper-box"
          :options="swiperOptionThumbs"
        >
          <swiper-slide class="home-consult-enterprise-list-box">
            <div>
              <img src="@/assets/img/skhbg.png" alt="服务商" />
              <p>深圳开鸿数字产业发展有限公司</p>
            </div>
          </swiper-slide>
          <swiper-slide class="home-consult-enterprise-list-box">
            <div>
              <img src="@/assets/img/sbbg.png" alt="服务商" />
              <p>三宝科技股份有限公司</p>
            </div>
          </swiper-slide>
          <swiper-slide class="home-consult-enterprise-list-box">
            <div>
              <img src="@/assets/img/dqbg.png" alt="服务商" />
              <p>河南驰诚电气股份有限公司</p>
            </div>
          </swiper-slide>
        </swiper>
      </div>
      <span>优质服务商</span>
      <div class="home-consult-service">
        <div v-for="el in enterpriseList" :key="el.name">
          <img :src="el.logo" alt="服务商" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      markList: [
        {
          name: "广东",
          left: "67%",
          top: "76%",
        },
        {
          name: "江苏",
          left: "74%",
          top: "46%",
        },
        {
          name: "山东",
          left: "70%",
          top: "40%",
        },
        {
          name: "重庆",
          left: "55%",
          top: "57%",
        },
        {
          name: "安徽",
          left: "70%",
          top: "50%",
        },
        {
          name: "福建",
          left: "74%",
          top: "67%",
        },
        {
          name: "甘肃",
          left: "48%",
          top: "40%",
        },
        {
          name: "广西",
          left: "57%",
          top: "78%",
        },
        {
          name: "河南",
          left: "65%",
          top: "46%",
        },
        {
          name: "湖北",
          left: "61%",
          top: "53%",
        },
        {
          name: "辽宁",
          left: "78%",
          top: "27%",
        },
        {
          name: "陕西",
          left: "56%",
          top: "44%",
        },
        {
          name: "浙江",
          left: "76%",
          top: "58%",
        },
      ],
      parkData: [
        {
          title: "南京未来科技城",
          desc: "南京未来科技城位于高铁南站至禄口机场发展“金轴”与绕越高速交汇点，规划面积38.1平方公里，核心区面积4.62平方公里，科教人才、区位交通、生态环境优势突出",
          url: "https://nj.jfh.com/future/nanjing/introduction/index.html",
          img: require("@/assets/img/js1.png"),
        },
        {
          title: "苏州高新区",
          desc: "开发建设以来，苏州高新区从无到有、从小到大，不仅成为苏州经济的重要增长极、自主创新的示范区和全市高新技术产业基地，而且成为苏州现代化都市的有机组成部分和最繁华的金融商贸区之一。",
          url: "https://szgx.jfh.com/enterPark.html",
          img: require("@/assets/img/js2.png"),
        },
        {
          title: "宜城软件园",
          desc: "宜城软件园以“平台+基地”的模式构建“园区大脑”，设立“一个平台一中心四大服务体系”，规划建设产品展示厅、体验馆等功能区域，着力搭建软硬件一体化综合管理智慧园区。",
          url: "",
          img: require("@/assets/img/js3.jpeg"),
        },
        {
          title: "中软国际Harmony智联科技创新园",
          desc: "2021年9月，盐城市人民政府与中软国际签署战略合作框架协议，双方合作共建鸿蒙科技创新园，并在盐城落地解放号鸿联联创中心，以场景构建带动技术应用，以数智服务汇聚产业生态，打造“两平台”+“三中心”+“N个示范场景”",
          url: "",
          img: require("@/assets/img/js4.jpeg"),
        },
        {
          title: "中软国际（苏州）鸿联车联网联创中心",
          desc: "“中软国际解放号(苏州)鸿联车联网联创中心”将打造鸿联车联网产业使能、释能、赋能平台，为长三角区域车联网产业的智能化发展构建整体的资源要素、解决方案、系统平台等数字基础设施和使能中心。",
          url: "",
          img: require("@/assets/img/js5.jpeg"),
        },
        {
          title: "合肥高新技术产业开发区",
          desc: "合肥国家高新技术产业开发区（简称合肥高新区）是1991年国务院首批设立的国家级高新区，面积128平方公里，常住人口20余万。合肥高新区是安徽省新兴产业门类最全、创新潜力与活力最优、金融资本最为活跃、政策集成度最高、人才资源最为丰富的地区之一，获得国家首批双创示范基地、国家自主创新示范区、国家生态工业示范园区、国家创新型科技园区、国家知识产权示范园区、全国首家综合性安全产业示范园区、全国模范劳动关系和谐工业园区等多项国家级荣誉，2016年，在全国国家高新区综合排名中位居第7位。",
          url: "https://hf.jfh.com/hefei/introduction/",
          img: require("@/assets/img/ah1.png"),
        },
        {
          title: "福建武夷高新技术园区",
          desc: "武夷智谷·软件园选址南平市武夷新区省道S303以东、新岭河以南，由福建武夷高新技术园区开发建设有限公司承建和运营招商管理，一期规划3.6平方公里(360公倾)，可建设面积150公倾,总建筑面积192万平方米，计划总投资65亿(含土地征迁和开发)，可容纳5万人。",
          url: "https://np.jfh.com/nanping/introduction/",
          img: require("@/assets/img/fj1.png"),
        },
        {
          title: "厦门软件园",
          desc: "厦门云上软件园地处厦门软件园三期，位于厦门集美新城核心区，毗邻大学城，背靠河南山，面向杏林湾，依山傍海，风景秀丽，是集美新城的最重要组成部分。厦门软件园三期总规划面积10平方公里,总投资460亿。",
          url: "https://xm.jfh.com/xiamen/introduction/",
          img: require("@/assets/img/fj2.png"),
        },
        {
          title: "兰州科技创新园",
          desc: '近年来，为坚持以开放促发展，加快新兴产业培育，兰州市城关区以国家"一带一路"建设、"大众创业万众创新"、华夏文明传承创新区建设、兰西城市群发展规划、兰白科技创新改革试验区建设等为行动抓手，充分发挥"一带一路"黄金节点区位优势，在兰西城市群建设中发挥示范带动作用，在兰白科技创新改革试验区建设中发挥科技引领作用，',
          url: "https://lz.jfh.com/lanzhou/introduction/index.html",
          img: require("@/assets/img/gs1.png"),
        },
        {
          title: "广州民营科技园",
          desc: "广州民营科技园（下称民科园）位于白云区中北部，是白云区委、区政府管理的唯一一个国家级高新区，被纳入珠三角国家自主创新示范区、广州市国际科技创新枢纽体系，并被确定为首批国家小型微型企业创新创业示范基地。",
          url: "https://by.jfh.com/baiyun/introduction/",
          img: require("@/assets/img/gz1.png"),
        },
        {
          title: "桂林经济开发区",
          desc: "桂林经开区位于美丽的漓江之西，属柳江水系，是桂林新区的重要组成部分，建设经开区是市委、市政府为了更好的保护漓江，贯彻落实“绿水青山就是金山银山”的科学理论，重振桂林工业雄风，加快国际旅游胜地建设步伐而做出的一个重大战略决策。",
          url: "https://gl.jfh.com/guilin/introduction/",
          img: require("@/assets/img/gx1.png"),
        },
        {
          title: "广西柳州柳东新区",
          desc: "柳东新区位于柳州市东北部，成立于2007年，统辖柳州高新区，整建制托管雒容镇和洛埠镇，辖区总面积约381.5平方公里，规划面积203平方公里，建设面积138平方公里，是广西壮族自治区党委、政府重点发展的三大城市新区之一，",
          url: "https://liuzhou.jfh.com/liuzhou/introduction/",
          img: require("@/assets/img/gx1.png"),
        },
        {
          title: "中软国际郑州鸿联联创营",
          desc: "郑州鸿联联创营围绕“赋能企业成长，联创解决方案；繁荣鸿蒙生态，加速产业集聚”，对上连接政府侧、集团总部，推动场景释放；对下赋能物联网、智能家居等产业，联创解决方案。",
          url: "",
          img: require("@/assets/img/hn1.png"),
        },
        {
          title: "武汉东湖新技术开发区",
          desc: "武汉东湖新技术开发区于1988年创建成立，1991年被国务院批准为首批国家级高新区，2001年被原国家计委、科技部批准为国家光电子产业基地，即“武汉·中国光谷”，2007年被国家发改委批准为国家生物产业基地，2009年被国务院批准为全国第二个国家自主创新示范区",
          url: "https://wh.jfh.com/wuhan/introduction/",
          img: require("@/assets/img/hb1.png"),
        },
        {
          title: "中软国际解放号（东北）数字经济协同创新基地",
          desc: "2020年11月，中软国际与辽宁省人民政府为推动辽宁省产业数字化和数字产业化建设签署战略合作协议，在沈抚投资建设“中软国际解放号（东北）数字经济协同创新基地”项目，并于2021年1月注册成立辽宁解放号网络科技有限公司，负责基地的整体建设和业务运营，以沈抚示范区为创新示范",
          url: "",
          img: require("@/assets/img/ln1.jpeg"),
        },
        {
          title: "解放号东北亚服务平台",
          desc: "中软国际解放号青岛云上软件园是中软国际在国内落户的首个云上软件园，坐落于高新区中科研发城，面积约1800平米，是高新区通过傍大引强战略链接华为系生态伙伴吸引的首个行业50强项目。",
          url: "",
          img: require("@/assets/img/sd1.png"),
        },
        {
          title: "济宁国家高新技术产业开发区",
          desc: "济宁国家高新区创建于1992年5月，辖5个街道，人口25万，面积255平方公里，是国家科技创新服务体系、创新型产业集群、战略性新兴产业知识产权集群管理、科技创业孵化链条试点高新区及省级人才管理改革试验区、山东省科技金融试点高新区。",
          url: "https://jn.jfh.com/jining/introduction/",
          img: require("@/assets/img/sd2.png"),
        },
        {
          title: "威海经济技术开发区",
          desc: "“中软国际（威海）云上软件园，位于威海市经济技术开发区香港路18号智慧大厦，园区交通便捷，大厦内部食堂、健身房配套完善，周边商场、餐饮、娱乐、医院等配套齐全，是经济技术开发区的核心位置。中软国际（威海）云上软件园以威海服务贸易产业园子园区的形式",
          url: "https://weihai.jfh.com/weihai/introduction/",
          img: require("@/assets/img/sd3.png"),
        },
        {
          title: "西安高新技术产业开发区",
          desc: "西安高新技术产业开发区是1991年经国务院首批批准设立的国家级高新区，是国家确定的六个建设世界一流科技园区之一，是国家自主创新示范区，全面创新改革试验先行区和中国（陕西）自由贸易试验区核心片区，2016年，实现营业总收入1.36万亿元，外贸进出口总额超过1500亿元，在全国146家国家级高新区综合排名第四。",
          url: "https://xa.jfh.com/xian/introduction/",
          img: require("@/assets/img/sx1.png"),
        },
        {
          title: "宁波国家高新区",
          desc: " 宁波国家高新区始建于1999年,是宁波市实施科教兴市“一号工程”和建设创新型城市的核心载体。2007年,经国务院批准升级为国家级高新区,成为全国第一家由省级高新区成功升级的国家高新区。",
          url: "https://nb.jfh.com/ningbo/introduction/",
          img: require("@/assets/img/zj1.png"),
        },
        {
          title: "嘉兴经济技术开发区",
          desc: " 2013年，为充分发挥国家级开发区在经济社会发展中的辐射、带动作用，根据浙江省委、省政府提出的高标准推进开发区整合提升工作的精神和要求，嘉兴市委、市政府决定以国家级开发区——嘉兴经济技术开发区（国际商务区）为核心区块对嘉兴工业园区（含嘉兴科技城）、嘉兴秀洲经济开发区、嘉兴港区（含嘉兴出口加工区）三个省级开发区（园区）进行整合提升，总面积达284.8平方公里",
          url: "http://jiaxing.jfh.com/jiaxing/introduction/",
          img: require("@/assets/img/zj2.png"),
        },
        {
          title: "中软国际（乌镇）鸿联联创中心",
          desc: "中软国际（乌镇）鸿联联创中心依托HarmonyOS的软件硬件生态，整体围绕构建一个鸿联云平台、一个鸿联云创新场景应用平台和四个中心（全球智慧场景展示中心、技术培训中心、协同创新中心、测试认证中心），打造使能、释能、赋能平台。",
          url: "",
          img: require("@/assets/img/zj3.png"),
        },
        {
          title: "重庆仙桃数据谷",
          desc: "2014年3月17日，重庆市政府第40次常务会研究，决定规划建设仙桃大数据产业园，重点布局大数据、小传感、海存储、云应用四大产业板块，发展金融大数据、健康大数据、教育大数据、跨境电商等高附加值产业。仙桃国际数据谷（简称仙桃谷）是适应全球大数据和智能硬件产业快速崛起趋势",
          url: "https://cq.jfh.com/chongqing/introduction/",
          img: require("@/assets/img/cq1.png"),
        },
      ],
      enterpriseList: [
        {
          logo: require("@/assets/img/allogo.png"),
          name: "苏州奥莱维信息技术有限公司",
          type: "智能家居",
        },
        {
          logo: require("@/assets/img/jhlogo.png"),
          name: "江苏吉华电子科技有限公司",
          type: "电子产品智能制造",
        },
        {
          logo: require("@/assets/img/fllogo.png"),
          name: "嘉兴泛联通信技术有限公司",
          type: "物联网无线通信",
        },
        {
          logo: require("@/assets/img/afglogo.png"),
          name: "苏州欧菲光科技有限公司",
          type: "智能汽车",
        },
        {
          logo: require("@/assets/img/bslogo.png"),
          name: "江苏博顺信息科技有限公司",
          type: "数字乡村",
        },
        {
          logo: require("@/assets/img/zkglogo.png"),
          name: "盐城中科高通量计算研究院有限公司",
          type: "人工智能",
        },
      ],
    };
  },
  computed: {
    swiperOptionThumbs() {
      return {
        loop: true,
        slidesPerView: "auto",
        spaceBetween: 12,
        freeMode: true,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.map-icon1::before,
.map-icon1::after {
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  content: "";
}
.map-icon1::before {
  animation: scale 2s infinite;
}
.map-icon1::after {
  animation: scale2 2s infinite;
}
@keyframes scale {
  0% {
    transform: scale(1);
    opacity: 0.6;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
}
@keyframes scale2 {
  0% {
    transform: scale(1);
    opacity: 0.6;
  }
  100% {
    transform: scale(3);
    opacity: 0;
  }
}
.home-serve {
  padding: 32px 0;
  background: url(../../assets/img/fwbg.png) no-repeat;
  background-size: 100% 100%;
  > p {
    font-size: 20px;
    color: rgba(0, 0, 0, 0.85);
    text-align: center;
    font-weight: 600;
    line-height: 28px;
    margin-bottom: 8px;
  }
  > span {
    display: block;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.65);
    text-align: center;
    line-height: 20px;
    margin-bottom: 24px;
  }
  > strong {
    font-size: 18px;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 600;
    display: block;
    line-height: 24px;
    padding: 0 16px;
    margin-bottom: 16px;
  }
  .home-consult {
    padding: 0 16px;
    .home-consult-swipe {
      background: #ffffff;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
      display: flex;
      align-items: center;
      padding: 0 12px;
      overflow: hidden;
      > p {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.85);
        line-height: 20px;
        font-weight: 600;
        margin-right: 12px;
        > span {
          color: #ea0b06;
        }
      }
      > div {
        flex: 1;
        .home-consult-list {
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 100%;
          > p {
            font-size: 12px;
            color: rgba(0, 0, 0, 0.65);
            line-height: 16px;
            max-width: 220px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          > img {
            width: 14px;
          }
        }
      }
    }
  }
  .home-consult-map {
    > strong {
      font-size: 16px;
      color: rgba(0, 0, 0, 0.85);
      display: block;
      line-height: 22px;
      margin: 16px 0;
      padding: 0 16px;
    }
    .home-consult-map-total {
      display: flex;
      justify-content: center;
      padding: 0 16px;
      > div {
        > p {
          display: flex;
          align-items: center;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.85);
          margin-bottom: 8px;
          &:last-child {
            margin-bottom: 0;
          }
          > img {
            width: 24px;
            margin-right: 8px;
          }
          > span {
            color: #ea0b06;
            font-weight: 600;
          }
        }
      }
    }
    .home-consult-map-info {
      /*width: 364px;
      height: 261px;*/
      width: 100vw;
      height: 0;
     /* background: url(../../assets/img/map.png) no-repeat;
      background-size: 100% 100%;*/
      position: relative;
      padding-bottom: 69.6%;
      >.info-img{
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 4px;
        z-index: 0;
      }
      > div {
        position: absolute;
        > span {
          position: absolute;
          width: 8px;
          height: 8px;
          left: 50%;
          margin-left: -5px;
          > i {
            border: 2px solid #fff;
            box-shadow: 0 0 4px 0 #ea0b06;
            background: #ea0b06;
            z-index: 10;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 10;
          }
          &::after {
            display: block;
            background: #ea0b06;
            left: 50%;
            top: 50%;
            margin: -4px 0 0 -4px;
            z-index: 2;
          }
          &::before {
            display: block;
            background: #ea0b06;
            left: 50%;
            top: 50%;
            margin: -4px 0 0 -4px;
            z-index: 2;
          }
        }
        > p {
          font-size: 12px;
          text-align: center;
          line-height: 16px;
          margin-top: 10px;
        }
      }
    }
    .home-consult-map-park {
      padding: 0 16px;
      white-space: nowrap;
      overflow-x: auto;
      margin-top: 18px;
      &::-webkit-scrollbar {
        display: none;
      }
      .home-consult-map-park-list {
        width: 160px;
        height: 90px;
        > div {
          width: 100%;
          height: 100%;
          position: relative;
          margin-right: 12px;
          > img {
            width: 100%;
            height: 100%;
            display: block;
          }
          > p {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 2;
            padding: 0 12px;
            line-height: 28px;
            font-size: 14px;
            color: #fff;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            background: linear-gradient(
                180deg,
                rgba(0, 0, 0, 0) 10%,
                rgba(0, 0, 0, 0.8) 90%
            );
          }
        }
      }
    }
  }
  .home-consult-enterprise {
    padding: 0 16px;
    margin-top: 28px;
    > strong {
      font-size: 18px;
      color: rgba(0, 0, 0, 0.85);
      font-weight: 600;
      display: block;
      line-height: 24px;
    }
    > span {
      font-size: 16px;
      color: rgba(0, 0, 0, 0.85);
      display: block;
      line-height: 22px;
      margin: 16px 0 12px;
      font-weight: 600;
    }
    .home-consult-enterprise-list {
      white-space: nowrap;
      overflow-x: auto;
      margin-top: 18px;
      &::-webkit-scrollbar {
        display: none;
      }
      .home-consult-enterprise-list-box {
        width: 299px;
        height: 163px;
        > div {
          width: 100%;
          height: 100%;
          position: relative;
          margin-right: 12px;
          > img {
            width: 100%;
            height: 100%;
            display: block;
          }
          > p {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 2;
            padding: 0 16px;
            line-height: 40px;
            font-size: 14px;
            color: #fff;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            background: linear-gradient(
                180deg,
                rgba(0, 0, 0, 0) 10%,
                rgba(0, 0, 0, 0.8) 90%
            );
          }
        }
      }
    }
    .home-consult-service {
      display: flex;
      flex-wrap: wrap;
      margin-top: -12px;
      > div {
        width: 106px;
        height: 63px;
        margin-top: 12px;
        margin-right: 12px;
        background: #fff;
        border: 1px solid #eee;
        &:nth-child(3n) {
          margin-right: 0;
        }
        > img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>
<style>
.subswiper-box{
  padding-bottom: 0;
}
</style>
