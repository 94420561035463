<template>
  <div class="home-center">
    <p>自主创新数字化转型赋能六中心</p>
    <span>自主创新数字化转型鸿小二</span>
    <van-button class="btn-red" size="small" @click="demandMove">国产自主创新技术升级</van-button>
    <img src="@/assets/img/centerbg.png" alt="centerbg">
    <div class="home-center-swiper">
      <swiper
        ref="swiperThumbs"
        class="swiper subswiper-box"
        :options="swiperOptionThumbs"
      >
        <swiper-slide
          v-for="(el, index) in centerData"
          :key="index"
          class="home-center-swiper-list"
        >
          <div class="home-center-swiper-tab-info">
            <img :src="el.img" alt="img" />
            <div>
                <p>{{ el.title }}</p>
                <span>{{ el.desc }}</span>
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  data(){
    return{
        centerData:[{
            title:'数智转型中心',
            img:require('@/assets/img/01center.png'),
            desc:'打造企业自主创新、数字化转型一站式服务中心，为当地企业提供政策辅导、定位咨询、场景牵引、国产IoT替代策略等服务；'
        },{
            title:'智联万物中心',
            img:require('@/assets/img/02center.png'),
            desc:'打造各类物联网及企业数字化转型的场景案例示范，结合实际场景案例提供转型参考体验，通过实感体验，引入物联网创新思维，加速政企数字化转型；'
        },{
            title:'智联共创中心',
            img:require('@/assets/img/03center.png'),
            desc:'采用“共创模式”，聚合优秀资源和能量来布局物联网应用服务，与当地传统行业的企业组成共创团队，并邀请第三方高科技人才加入，从开鸿操作系统根技术出发打通数据感知、数据传输、云平台等各个层级，进行行业应用的联合创新；'
        },{
            title:'开源创新中心',
            img:require('@/assets/img/04center.png'),
            desc:'携手深开鸿，基于鸿联云平台汇聚的国产开源创新生产要素，为企业提供国产开源硬件、系统及组件支持，并拉通当地IoT行业生态企业，组建IoT开源行业联盟，联合联盟企业共同制定开源IoT行业技术规范；'
        },{
            title:'未来实验中心',
            img:require('@/assets/img/05center.png'),
            desc:'整合网络条件、仪器设备、技术团队、智库专家、产业链合作资源，为行业客户的方案选型、概念验证、样品研发、生态链合作、行业培训等提供全新的平台与全面的技术支撑；'
        },{
            title:'鸿联英才中心',
            img:require('@/assets/img/06center.png'),
            desc:'结合园区和企业数字化转型实际需求，引入各类开源资源、各类培训课程，以培养实用型人才为目标，推出自主可控物联网实训、工业互联网实训和智能制造实训等课程体系，为地方数字化转型培训实战型人才；'
        }]
    }
  },
  computed: {
    swiperOptionThumbs() {
      return {
        loop:true,
        slidesPerView: 'auto',
        centeredSlides: true,
        spaceBetween: 12,
      };
    },
  },
  methods:{
    demandMove(){
      localStorage.removeItem('orderVal')
      this.$router.push('/demandMove')
    }
  }
}
</script>

<style lang="scss" scoped>
.home-center{
    background: #EEF0F2;
    padding: 32px 0 0;
    >p{
        font-size: 20px;
        color: rgba(0,0,0,0.85);
        text-align: center;
        font-weight: 600;
        line-height: 28px;
        margin-bottom: 8px;
    }
    >span{
        display: block;
        font-size: 14px;
        color: rgba(0,0,0,0.65);
        text-align: center;
        line-height: 20px;
        margin-bottom: 16px;
    }
    >button{
        display: block;
        width: 212px;
        margin: 0 auto;
        font-size: 14px;
    }
    >img{
        max-width: 100%;
        display: block;
    }

    .home-center-swiper-list{
        width: 210px !important;
        height: 293px;
        box-shadow: 0 8px 13px 0 rgba(0,0,0,0.07);
        margin-bottom: 34px;
        .home-center-swiper-tab-info{
            padding: 16px 12px;
            position: relative;
            height: 100%;
            >img{
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                display: block;
                width: 100%;
                height: 100%;
            }
            >div{
                position: relative;
                z-index: 2;
                >p{
                    font-size: 14px;
                    color: rgba(0,0,0,0.85);
                    line-height: 20px;
                    margin-bottom: 8px;
                    font-weight: bold;
                }
                >span{
                    display: block;
                    font-size: 12px;
                    color: rgba(0,0,0,0.65);
                    line-height: 18px;
                }
            }
        }
    }
}
</style>
<style>
.subswiper-box{
  padding-bottom: 32px;
}
</style>
