<template>
  <div class="home-about">
    <strong>关于鸿联</strong>
    <p>
      鸿联联创营是中软国际打造的AloT平台品牌，致力基于平台打造自主创新AIoT生态，旨在以需求为驱动、以场景为路径、以政府为先导、以区域为单元，探索对接服务国产物联网南北向生态的新模式
    </p>
    <div class="home-about-content">
      <div class="home-about-tag">
        <p v-for="el in aboutTitle" :key="el">
          <span :class="{'active':el == aboutDate}" @click="aboutDate = el">{{ el }}</span>
        </p>
      </div>
      <div class="home-about-tag-info">
        <p>
          <img src="@/assets/img/zd@2x.png" alt="img" />
          <span>{{ aboutDate }}</span>
        </p>
        <div class="home-about-tag-info-list">
          <div v-for="el in aboutData" :key="el.month">
            <span><i></i> {{ el.month }}</span>
            <div>
              <div v-for="(i, index) in el.list" :key="index">
                <span><img :src="i.img" alt="img" /></span>
                <p>{{ i.info }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      aboutTitle: ["2022", "2021", "2020", "2019", "2017"],
      aboutInfo: [
        {
          year: "2017",
          month: "7月",
          info: "成为华为云首家同舟共济合作伙伴，共拓公有云市场",
          img: require("@/assets/img/ad1.png"),
        },
        {
          year: "2017",
          month: "11月",
          info: "发起并成功举办首届全球程序员节",
          img: require("@/assets/img/ad2.png"),
        },
        {
          year: "2019",
          month: "01月",
          info: "获评中国信息技术服务十年十大领军企业",
          img: require("@/assets/img/ad3.png"),
        },
        {
          year: "2019",
          month: "07月",
          info: "携手华为共建鲲鹏产业生态",
          img: require("@/assets/img/ad4.png"),
        },
        {
          year: "2020",
          month: "04月",
          info: "鲲鹏计算产业生态重庆中心揭牌成立",
          img: require("@/assets/img/ad5.png"),
        },
        {
          year: "2020",
          month: "09月",
          info: "与北理工战略合作，进军智能网联汽车领域",
          img: require("@/assets/img/ad6.png"),
        },
        {
          year: "2020",
          month: "09月",
          info: "“鸿蒙开天地，联创照未来” 中软国际（乌镇）鸿联联创中心全球首发",
          img: require("@/assets/img/ad7.png"),
        },
        {
          year: "2020",
          month: "12月",
          info: "荣获HUAWEI HiLink“卓越合作伙伴 生态使能服务”大奖",
          img: require("@/assets/img/ad8.png"),
        },
        {
          year: "2020",
          month: "12月",
          info: "华为云同舟共济合作伙伴业绩第一，实现四年 20 倍跨越式增长",
          img: require("@/assets/img/ad9.png"),
        },
        {
          year: "2021",
          month: "01月",
          info: "携手打造“数字化引领转型升级”标杆城市",
          img: require("@/assets/img/ad10.png"),
        },
        {
          year: "2021",
          month: "01月",
          info: "与北理工共建信创学院",
          img: require("@/assets/img/ad11.png"),
        },
        {
          year: "2021",
          month: "04月",
          info: "蝉联 Gartner2020 全球 IT 服务 TOP100，排名晋升",
          img: require("@/assets/img/ad12.png"),
        },
        {
          year: "2021",
          month: "04月",
          info: "入驻华为开发者联盟生态市场，打造智能物联网产品联合销售线上渠道",
          img: require("@/assets/img/ad13.png"),
        },
        {
          year: "2021",
          month: "08月",
          info: "与华为签署鸿蒙生态合作协议",
          img: require("@/assets/img/ad14.png"),
        },
        {
          year: "2021",
          month: "08月",
          info: "携手清华大学、国汽智联、国汽智控探索智能网联汽车发展新方向",
          img: require("@/assets/img/ad15.png"),
        },
        {
          year: "2021",
          month: "09月",
          info: "中软国际蝉联2021中国软件百强",
          img: require("@/assets/img/ad16.png"),
        },
        {
          year: "2021",
          month: "10月",
          info: "鸿蒙之城 有迹可循｜中软国际全面参与华为开发者大会2021",
          img: require("@/assets/img/ad17.png"),
        },
        {
          year: "2021",
          month: "10月",
          info: "中软国际设立智能物联网业务集团",
          img: require("@/assets/img/ad18.png"),
        },
        {
          year: "2022",
          month: "01月",
          info: "中软国际荣获深智城“一网统管”项目组“突出贡献奖”",
          img: require("@/assets/img/ad19.png"),
        },
        {
          year: "2022",
          month: "03月",
          info: "联创共赢，中软国际成为金蝶“全国性交付伙伴”",
          img: require("@/assets/img/ad20.png"),
        },
      ],
      aboutDate: "2022",
      aboutData: [],
    };
  },
  mounted() {
    this.getAboutInfo();
  },
  watch:{
    aboutDate:function(val){
        this.getAboutInfo();
    }
  },
  methods: {
    getAboutInfo() {
      let { aboutDate, aboutInfo } = this;
      let data = [];
      let months = [];
      let aboutData = [];
      for (let index = 0; index < aboutInfo.length; index++) {
        if (aboutInfo[index].year == aboutDate) {
          months.push(aboutInfo[index].month);
          data.push(aboutInfo[index]);
        }
      }
      let set = new Set(months);
      months = Array.from(set);
      for (let i = 0; i < months.length; i++) {
        let obj = {
          month: months[i],
          list: [],
        };
        for (let index = 0; index < data.length; index++) {
          if (months[i] == data[index].month) {
            obj.list.push(data[index]);
          }
        }
        aboutData.push(obj);
      }
      this.aboutData = aboutData;
    },
  },
};
</script>

<style lang="scss" scoped>
.home-about {
  padding: 32px 0;
  background: url(../../assets/img/aboutbg.png) no-repeat;
  background-size: 100%;
  > strong {
    display: block;
    font-size: 20px;
    color: rgba(0, 0, 0, 0.85);
    text-align: center;
    line-height: 28px;
    margin-bottom: 8px;
  }
  > p {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.65);
    text-align: center;
    line-height: 20px;
    margin-bottom: 24px;
    padding: 0 16px;
  }
  .home-about-content {
    display: flex;
    justify-content: space-between;
    padding: 0 16px;
    position: relative;
    &::after{
        content: '';
        position: absolute;
        top: 10px;
        left: 68px;
        bottom: 12px;
        width: 1px;
        background: rgba(0,0,0,0.15);
    }
    .home-about-tag {
      width: 52px;
      > p {
        margin-bottom: 28px;
        display: flex;
        justify-content: center;
        &:last-child {
          margin-bottom: 0;
        }
        > span {
          font-size: 12px;
          color: rgba(0, 0, 0, 0.45);
          line-height: 20px;
          position: relative;
          &::after {
            content: "";
            position: absolute;
            height: 2px;
            left: 0;
            bottom: -4px;
            right: 0;
            background: transparent;
          }
          &.active{
            color: #EA0B06;
            &::after{
                background: #FF6759;
            }
          }
        }
      }
    }
    .home-about-tag-info{
        flex: 1;
        padding-left: 18px;
        >p{
            margin-bottom: 12px;
            display: flex;
            position: relative;
            >img{
                position: absolute;
                width: 12px;
                top: 5px;
                left: -24px;
                z-index: 2;
            }
            >span{
                width: 42px;
                height: 20px;
                background: #EA0B06;
                color: #fff;
                font-size: 12px;
                text-align: center;
                line-height: 20px;
            }
        }
        .home-about-tag-info-list{
            >div{
                display: flex;
                justify-content: space-between;
                >span{
                    font-size: 12px;
                    color: rgba(0,0,0,0.65);
                    font-weight: 600;
                    line-height: 86px;
                    position: relative;
                    >i{
                        position: absolute;
                        content: '';
                        display: block;
                        width: 12px;
                        height: 12px;
                        border-radius: 50%;
                        border: 3px solid #fff;
                        background: rgb(255,103,89);
                        top: 37px;
                        left: -24px;
                        z-index: 2;
                    }
                }
                >div{
                    width: 238px;
                    >div{
                        background: #FFFFFF;
                        border: 1px solid rgba(0,0,0,0.09);
                        padding: 12px;
                        display: flex;
                        margin-bottom: 12px;
                        >span{
                            width: 60px;
                            height: 60px;
                            margin-right: 12px;
                            >img{
                                max-width: 100%;
                                max-height: 100%;
                                display: block;
                                margin: 0 auto;
                            }
                        }
                        >p{
                            flex: 1;
                            font-size: 14px;
                            color: rgba(0,0,0,0.65);
                            line-height: 20px;
                        }
                    }
                }
            }
        }
    }
  }
}
</style>