<template>
  <div v-wechat-title="'鸿联云'">
    <div class="home-banner">
      <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
        <van-swipe-item class="home-banner-list">
          <img src="@/assets/img/banner图@3x.png" alt=""/>
        </van-swipe-item>
      </van-swipe>
    </div>
    <platform/>
    <center/>
    <serve/>
    <business/>
    <result/>
    <about/>
    <share :val="shareInfo"/>
  </div>
</template>

<script>
import share from '../components/share.vue'
import platform from './platform.vue'
import center from './center.vue'
import serve from './serve.vue'
import business from './business.vue'
import result from './result.vue'
import about from './about.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    platform,
    center,
    serve,
    business,
    result,
    about,
    share
  },
  computed: mapGetters(['userInfo']),
  data () {
    return {
      shareInfo: {
        title: '鸿联云',
        desc: '致力基于平台打造自主创新AIoT生态提供端到端的国产化替代全栈服务',
        img: require('@/assets/img/logoinimg.png')
      }
    }
  },
  mounted () {
    document.title = '鸿联云'
    if (this.$route.query.code) {
      this.wechatUtil.saveWechatInfo(this.$route.query.code, localStorage.getItem('openId'))
    }
  }
  ,
  methods: {}
}
</script>

<style lang="scss" scoped>
</style>
<style>
.home-banner-list > img {
  max-width: 100%;
  display: block;
}

.home-banner .van-swipe__indicator {
  width: 30px;
  height: 4px;
  background: rgba(000, 000, 000, 0.15);
  border-radius: 0;
}

.home-banner .van-swipe__indicator.van-swipe__indicator--active {
  background-color: #EA0B06 !important;
}
</style>
