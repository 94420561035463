<template>
  <div class="home-result">
    <strong>自主创新数字化转型赋能经典成果</strong>
    <p>跨行业跨领域为客户提供全生命周期自主创新数字化转型服务</p>
    <div class="home-result-info">
        <div class="home-result-tag">
            <span :class="{'active':resultTagIndex == 1}" @click="resultTagIndex = 1">浙江•乌镇</span>
            <span :class="{'active':resultTagIndex == 2}" @click="resultTagIndex = 2">江苏•苏州</span>
            <span :class="{'active':resultTagIndex == 3}" @click="resultTagIndex = 3">江苏•盐城</span>
            <span :class="{'active':resultTagIndex == 4}" @click="resultTagIndex = 4">河南•郑州</span>
        </div>
        <div class="home-result-tag-info">
            <div v-show="resultTagIndex == 1">
                <img src="@/assets/img/wzfm.jpg" alt="wzfm">
                <p>中软国际（乌镇）鸿联联创中心</p>
            </div>
            <div v-show="resultTagIndex == 2">
                <img src="@/assets/img/szfm.jpg" alt="szfm">
                <p>中软国际（苏州）鸿联车联网联创中心</p>
            </div>
            <div v-show="resultTagIndex == 3">
                <img src="@/assets/img/ycfm.jpg" alt="ycfm">
                <p>盐城Harmony智联科技创新园</p>
            </div>
            <div v-show="resultTagIndex == 4">
                <img src="@/assets/img/zhfm.jpg" alt="zhfm">
                <p>中软国际郑州“中原数字总部”基地</p>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return{
            resultTagIndex:1
        }
    }
}
</script>

<style lang="scss" scoped>
.home-result{
    padding: 32px 0;
    background: url(../../assets/img/cgbg.png) no-repeat;
    background-size: 100% 100%;
    > strong {
        display: block;
        font-size: 20px;
        color: rgba(0, 0, 0, 0.85);
        text-align: center;
        line-height: 28px;
        margin-bottom: 8px;
    }
    > p {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.65);
        text-align: center;
        line-height: 20px;
        margin-bottom: 16px;
        padding: 0 16px;
    }
    .home-result-info{
        .home-result-tag{
            display: flex;
            justify-content: space-between;
            padding: 0 16px;
            border-bottom: 1px solid rgba(0,0,0,0.09);
            >span{
                height: 36px;
                line-height: 36px;
                border-bottom: 2px solid transparent;
                font-size: 14px;
                color: rgba(0, 0, 0, 0.65);
                &.active{
                    color: #EA0B06;
                    border-color: #EA0B06;
                }
            }
        }
        .home-result-tag-info{
            padding: 16px 16px 0;
            >div{
                position: relative;
                height: 150px;
                >img{
                    width: 100%;
                    height: 100%;
                    display: block;
                }
                >p{
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    z-index: 2;
                    padding: 0 12px;
                    line-height: 40px;
                    font-size: 16px;
                    color: #fff;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    background: linear-gradient(
                        180deg,
                        rgba(0, 0, 0, 0) 10%,
                        rgba(0, 0, 0, 0.8) 90%
                    );
                }
            }
        }
    }
}
</style>
