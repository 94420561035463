<template>
  <div class="home-business">
    <strong>自主创新数字化转型赋能准商机</strong>
    <span>自主创新数字化转型预备或精准场景释放</span>
    <div class="home-business-topic">
      <p>专题场景</p>
      <div class="home-business-topic-list">
        <div v-for="el in topicData" :key="el.title" @click="topicInfo(el)">
          <p>{{el.title}}</p>
          <img :src="el.img" :alt="el.title">
        </div>
      </div>
      <p>
        <span :class="{'active':businessTag == 1}" @click="businessTag = 1">场景清单</span>
        <span class="center">|</span>
        <span :class="{'active':businessTag == 2}" @click="businessTag = 2">最新揭榜</span>
      </p>
      <div>
        <div v-show="businessTag == 1" v-for="el in sceneList" :key="el.name" class="scene-list">
          <div class="scene-list-title">
            <p>{{el.name}}</p>
            <span><i :class="{ jbz: el.state == '揭榜中' }"></i>{{el.state}}</span>
          </div>
          <div class="scene-list-date">
            <p>发榜时间: <span>{{el.startDate}}</span></p>
            <p>截止时间: <span>{{el.endDate}}</span></p>
          </div>
        </div>
        <div v-show="businessTag == 2" v-for="el in newsList" :key="el.name" class="scene-list">
          <div class="scene-list-title">
            <p style="width:100%">{{el.name}}</p>
          </div>
          <div class="scene-list-date">
            <p>揭榜单位: <span>{{el.enterprise}}</span></p>
            <p>发榜时间: <span>{{el.date}}</span></p>
          </div>
        </div>
      </div>
    </div>
    <van-popup
      v-model="topicDetailShow"
      class="home-business-popup"
      :close-on-click-overlay="false"
    >
      <div class="home-business-topic-desc">
        <img :src="topicDetail.imgBg" alt="gb" />
        <div>
          {{topicDetail.desc}}
        </div>
        <p @click="topicDetailShow = false">
          <img src="@/assets/img/codebg.png" alt="gb" />
        </p>
      </div>
    </van-popup>
  </div>
</template>

<script>
export default {
  data(){
    return{
      topicData:[{
        title:'5G+教育专题',
        img:require('@/assets/img/5G+jy@3x.png'),
        imgBg:require('@/assets/img/01@3x.png'),
        desc:'“5G+教育”有哪些应用场景 5G影响教育的技术因素主要集中在：4K/8K高清视频交互分析，超高速、低延时的及分布式存储。将这些技术特征与教育进行匹配，“5G+教育”的应用场景就会清晰可见。'
      },{
        title:'5G+科技专题',
        img:require('@/assets/img/5G+kj@3x.png'),
        imgBg:require('@/assets/img/02@3x.png'),
        desc:'“5G+教育”有哪些应用场景 5G影响教育的技术因素主要集中在：4K/8K高清视频交互分析，超高速、低延时的及分布式存储。将这些技术特征与教育进行匹配，“5G+教育”的应用场景就会清晰可见。'
      },{
        title:'5G+金融专题',
        img:require('@/assets/img/5G+jinrong@3x.png'),
        imgBg:require('@/assets/img/03@3x.png'),
        desc:'5G正在渗透并重塑各大行业，而金融也是5G重点发力的领域之一。 2020年12月，中国信通院发布了《“5G+金融应用发展研究报告》。'
      },{
        title:'5G+交通专题',
        img:require('@/assets/img/5G+jt@3x.png'),
        imgBg:require('@/assets/img/04@3x.png'),
        desc:'5G+智慧交通的功能有：交通感知——非现场电子警察执法，交通动脉——信号控制系统，交通窗口——信息诱导发布系统，交通秩序——路边停车管理系统，交通小脑——全息路口感知系统。通俗地讲，智能交通的功能分为智能交管、智能疏通和智能驾驶三部分。'
      },{
        title:'5G+医疗专题',
        img:require('@/assets/img/5G+yl@3x.png'),
        imgBg:require('@/assets/img/05@3x.png'),
        desc:'5G+智慧医疗是通过高清视频实时互动与云AR、3D医疗建模等技术，大幅度提升医联体远程诊疗、精准扶贫能力和急救能力。医疗包括两部分：一是公共卫生服务范围，包括疾病预防、计划免疫、妇幼保健、急救、采血服务及职业病防治等领域。二是基本医疗。'
      },{
        title:'5G+政务专题',
        img:require('@/assets/img/5G+z2@3x.png'),
        imgBg:require('@/assets/img/06@3x.png'),
        desc:'5G+智慧政务是运用云计算、大数据、物联网等技术，通过监测、整合、分析、智能响应，实现各职能部门的各种资源的高度整合，提高政府的业务办理和管理效率。'
      }],
      sceneList:[{
        name:'数字孪生平台及应用项目',
        startDate:'2022/6/15',
        endDate:'1个月后',
        state:'揭榜中'
      },{
        name:'施工现场智慧工地云平台',
        startDate:'2022/6/10',
        endDate:'15天后',
        state:'揭榜中'
      },{
        name:'智慧城市运行管理中心项目',
        startDate:'2022/5/25',
        endDate:'2天后',
        state:'揭榜中'
      }],
      newsList:[{
        name:'无人机光传输空地监测系统',
        enterprise:'南京东大智能化系统有限公司',
        date:'2022/6/15'
      },{
        name:'互联网大屏在线教育智慧平台',
        enterprise:'大汉软件股份有限公司',
        date:'2022/6/14'
      },{
        name:'智慧农路系统',
        enterprise:'江苏苏盟信息技术有限公司',
        date:'2022/6/8'
      }],
      businessTag:1,
      topicDetail:'',
      topicDetailShow:false
    }
  },
  methods:{
    topicInfo(val){
      this.topicDetailShow = true
      this.topicDetail = val
    }
  }
};
</script>

<style lang="scss" scoped>
.home-business {
  padding: 32px 16px;
  background: url(../../assets/img/sjbg.png) no-repeat;
  background-size: 100% 100%;
  > strong {
    font-size: 20px;
    color: rgba(0, 0, 0, 0.85);
    text-align: center;
    line-height: 28px;
    margin-bottom: 8px;
    display: block;
  }
  >span{
    display: block;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.65);
    text-align: center;
    line-height: 20px;
    margin-bottom: 24px;
  }
  .home-business-topic{
    >p{
      font-size: 18px;
      color: rgba(0,0,0,0.85);
      font-weight: 600;
      margin-bottom: 16px;
      display: flex;
      line-height: 26px;
      >span{
      //  line-height: 26px;
        &.active{
          color: rgba(234,11,6,0.85);
        }
        &:first-child{
         // padding-right: 8px;
          //border-right: 1px solid rgba(0,0,0,0.45);
          //margin-right: 8px;
        }
      }
      >.center{
        color: rgba(0,0,0,0.45);
        margin: 0 8px;
      }

    }
    .home-business-topic-list{
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-bottom: 12px;
      >div{
        width: 165px;
        height: 51px;
        background: url(../../assets/img/Mask@2x.png) no-repeat;
        background-size: 100% 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 12px;
        margin-bottom: 12px;
        >p{
          font-size: 14px;
          color: rgba(0,0,0,0.85);
          font-weight: 600;
        }
        >img{
          width: 48px;
        }
      }
    }
    .scene-list{
      border: 1px solid rgba(0,0,0,0.09);
      padding: 12px;
      margin-bottom: 12px;
      &:nth-child(3n){
        margin-bottom: 0;
      }
      .scene-list-title{
        display: flex;
        justify-content: space-between;
        align-items: center;
        >p{
          width: 240px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 16px;
          color: rgba(0,0,0,0.85);
          line-height: 22px;
          font-weight: 600;
        }
        >span{
          display: flex;
          align-items: center;
          font-size: 14px;
          color: rgba(0,0,0,0.65);
          line-height: 22px;
          >i{
            border-radius: 50%;
            width: 6px;
            height: 6px;
            background: #ddd;
            margin-right: 8px;
            &.jbz{
              background: #52c41a;
            }
          }
        }
      }
      .scene-list-date{
        display: flex;
        justify-content: space-between;
        margin-top: 8px;
        >p{
          font-size: 14px;
          color: rgba(0,0,0,0.45);
          line-height: 22px;
          max-width: 54%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          >span{
            color: rgba(0,0,0,0.65);
          }
        }
      }
    }
  }
}
.home-business-topic-desc{
  position: relative;
  background: #fff;
  >img{
    display: block;
    max-width: 100%;
  }
  >div{
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 2;
    font-size: 14px;
    color: rgba(0,0,0,0.85);
    line-height: 24px;
    padding: 24px;
    text-align: justify;
  }
  >p{
    position: absolute;
    z-index: 2;
    bottom: -54px;
    left: 0;
    right: 0;
    >img{
      display: block;
      width: 30px;
      margin: 0 auto;
    }
  }
}
</style>
<style>
.home-business-popup{
  width: 80%;
  overflow-y: initial;
}
</style>
